<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="delete-modal-item-component final-modal"  @before-close="beforeClose">
        <div class="v-modal-content">
            <div class="v-modal-body">
                <span class="pull-right" style="cursor:pointer;" @click="closeModal()">
                    <i class="icon icon-close" style="font-size: 18px!important; color: #00448b;"></i>
                </span>
                <div class="v-modal-layout">
                    <div class="text-center pl-6 mt-4">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/accept-icon.svg" class="deleteicon" />
                    </div>
                </div>
                <div class="v-modal-footer text-center pt-2 mt-3 pb-4 modal-bg">
                    <div class="font-weight-bold text-center alert-txt">Accepted!!</div>
                    <div class="fs-16 fw-600 text-center mt-3">Order Accepted Successfully</div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import VModal from 'vue-js-modal'
import ManageOrderService from './mixins/manage_order.js'
import { EventBus } from './eventBus/nav-bar-event.js'
import { types } from 'util'
import Avatar from 'vue-avatar'
import Swal from 'sweetalert2'

function initialState() {
    return {
        temp_product_quantity: 0,
        isSpoilt: false,
        reason: '',
        comments: '',
        deletereasons: [],
        isSelected: false,
        commentsTrue: false,
        commentsLength: 100,
        isSubmitted: false,
        voidTrue: false,
        deleted_id: null
    }
}
export default {
    props: [
        'modal_name',
        'order_id',

    ],
    mixins: [ManageOrderService],
    data() {
        return initialState()
    },
    methods: {
        // opened() {
        //     this.getReasons()
        // },
        beforeClose() {
            this.reset()
        },
        reset() {
            Object.assign(this.$data, initialState())
        },
        DeleteModal() {
            this.$modal.show('delete-item-modal')
        },
        closeModal() {
            this.$modal.hide(this.modal_name)
        },
    },
    mounted() {

    },
    created() {
    },
    components: {
        Swal,
        VModal,
        Avatar
    }
}
</script>
<style>
.product-avatar.content-center {
    margin: 0 auto !important;
}
.delete-modal-item-component.v--modal-overlay .v--modal-box {
    top: 78px !important;
    left: 0px !important;
    max-width: 700px !important;
    width: 430px !important;
    box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.43) !important;
    border-radius: 15px !important;
    max-height: 580px !important;
    margin: 0 auto !important;
}
.delete-modal-item-component.v--modal-overlay .v--modal-box .v-modal-layout {
    max-height: inherit !important;
}
.delete-modal-item-component .v-modal-body {
    overflow: hidden !important;
    height: 250px !important;
}
.delete-modal-item-component .v-modal-content {
    background-color: #f2f5f8;
}
.deleteItemModal {
    height: 36px !important;
    border-radius: 7px !important;
    border: solid 0.5px #cdd2da !important;
    text-align: center !important;
    width: 140px;
    height: 30px !important;
}
.deleteItemText {
    font-size: 22px;
    font-weight: bold;
    color: #303031;
}
.deleteNextItem {
    font-size: 13px;
    font-weight: 600;
    color: #707070;
}
.form-check-label input[type='radio']:checked ~ .radio-icon:before,
.form-check-label
  input[type='radio']:checked
  ~ .custom-control-indicator:before,
.custom-checkbox input[type='radio']:checked ~ .radio-icon:before,
.custom-checkbox input[type='radio']:checked ~ .custom-control-indicator:before,
.custom-radio input[type='radio']:checked ~ .radio-icon:before,
.custom-radio input[type='radio']:checked ~ .custom-control-indicator:before {
    border-color: #00448b !important;
}
.form-check-label input[type='radio']:checked ~ .radio-icon:before,
.form-check-label
  input[type='radio']:checked
  ~ .custom-control-indicator:before,
.custom-checkbox input[type='radio']:checked ~ .radio-icon:before,
.custom-checkbox input[type='radio']:checked ~ .custom-control-indicator:before,
.custom-radio input[type='radio']:checked ~ .radio-icon:before,
.custom-radio input[type='radio']:checked ~ .custom-control-indicator:before {
    border-color: #00448b !important;
}
.form-check-label input[type='radio'] ~ .radio-icon + span,
.form-check-label input[type='radio'] ~ .custom-control-indicator + span,
.custom-checkbox input[type='radio'] ~ .radio-icon + span,
.custom-checkbox input[type='radio'] ~ .custom-control-indicator + span,
.custom-radio input[type='radio'] ~ .radio-icon + span,
.custom-radio input[type='radio'] ~ .custom-control-indicator + span {
    padding-left: 0px !important;
}
.reasonVoid {
    background-image: linear-gradient(to bottom, #ff3a3a, #c51a1a);
    padding: 8px 24px;
    border-radius: 18px;
    color: #fff;
    font-weight: bold;
}
.reasonCancel {
    background-image: linear-gradient(to bottom, #f5a623, #ffa20b);
    padding: 8px 24px;
    border-radius: 18px;
    color: #303031;
    font-weight: bold;
}
.customReasons {
    color: #00448b;
    border-radius: 6px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    cursor: pointer;
    padding: 13px 10px;
    text-align: center;
    margin: 10px;
    font-weight: bold;
    text-transform: capitalize;
    word-break: break-word;
    height: 105px;
}
.isSelectedReason {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #1ebe5a, #1a9347);
    border-radius: 6px;
    cursor: pointer;
    padding: 13px 10px;
    text-align: center;
    margin: 10px;
    color: #fff !important;
    font-weight: bold;
    text-transform: capitalize;
    word-break: break-word;
}
.displayNone {
    display: none !important;
}
.delete-icon {
    width:160px !important
}
.alert-txt {
    font-size :20px !important
}
.btn-style {
    height: 32px !important;
    padding: 0 18px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    line-height: 32px !important;
    text-transform: none;
    font-weight: 600 !important;
}
.deleteicon {
    height:120px !important;
}
</style>
